/* @flow */

import * as React from 'react'
import { ProductTemplate } from '../templates'

type Props = {
  location: {
    href: string,
    state: Object,
  },
}

export default function Form(props: Props) {
  const { location } = props
  return (
    <ProductTemplate
      url={location.href}
      media="illu_speciality-delicatessen.png"
      title="Choisissez la caisse enregistreuse pour épicerie"
      name="Épicerie"
      label="Épicerie"
      description="Wino a développé pour l'épicerie une solution de caisse enregistreuse moderne dédiée à votre métier. S'équiper d'une caisse enregistreuse spécialisée pour épicerie vous aidera à dégager un temps précieux pour conseiller vos clients et développer votre point de vente"
      body={[
        {
          name: 'features',
          items: [
            {
              media: 'icon_speciality-delicatessen_catalog.png',
              title: 'Catalogue optimisé',
              content:
                "Travaillez avec un catalogue produits pensé pour les besoins d'une épicerie fine",
            },
            {
              media: 'icon_speciality-delicatessen_cashing.png',
              title: 'Encaissement rapide',
              content:
                "Grâce aux outils connectés à la caisse enregistreuse, encaissez en un clin d'oeil",
            },
            {
              media: 'icon_speciality-delicatessen_stocks.png',
              title: 'Gestion de stock',
              content: 'Maîtrisez vos stocks pour éviter les ruptures et les stocks dormants',
            },
            {
              media: 'icon_speciality-delicatessen_fidelite.png',
              title: 'Fidélisation client',
              content:
                'Mettez en place un système de fidélité sur mesure pour récompenser vos clients',
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'mujeres',
              media: 'screenshot_speciality-delicatessen_products.png',
              alt: 'catalogue optimisé',
              label: 'Catalogue optimisé',
              title: 'Une caisse enregistreuse avec un catalogue optimisé pour les épiceries',
              content:
                "Qui dit magasin spécialisé dit produits spécialisés. Le catalogue produit Wino s'adapte aux spécificités de l’épicerie. Créez vos produits aux unités de mesure et formats variés : kilogramme, litre, produit à la pièce, en vrac et paniers garnis. Gérez vos prix avec des grilles tarifaires multiples, des prix dégressifs et imprimez des étiquettes avec codes-barres.",
            },
            {
              decoration: 'gypsyeyes',
              media: 'screenshot_speciality-delicatessen_cashregister.png',
              alt: 'Encaissement rapide',
              label: 'Encaissement rapide',
              title: 'La caisse enregistreuse pour épicerie pour encaisser rapidement',
              content:
                'Avec un lecteur de codes-barres connecté à la caisse enregistreuse tactile, scannez rapidement vos produits à encaisser. Pesez et encaissez tout aussi vite vos articles d’épicerie au vrac grâce à une balance connectée à la caisse enregistreuse. Avec Wino, profitez d’un parcours d’achat en magasin fluide pour tous vos clients.',
            },
          ],
        },
        {
          name: 'winobox',
          title: "Besoin d'un devis personnalisé pour équiper votre épicerie avec Wino ?",
          media: 'photo_askquotation.png',
          content:
            'Toute l’équipe est à votre écoute et connaît votre métier sur le bout des doigts !',
          buttonText: 'Demander un devis',
          buttonLink: '/devis',
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'quarantine',
              media: 'screenshot_speciality-delicatessen_supplierorder.png',
              alt: 'Gestion de stock',
              label: 'Gestion de stock',
              title: 'Un logiciel de gestion de stock complet pour votre épicerie fine',
              content:
                'Depuis la caisse enregistreuse et le tableau de bord, suivez les stocks des produits de votre épicerie fine en temps réel. Complétez vos stocks en créant des commandes fournisseurs. Suivez les stocks sortants sur vos différents canaux de vente et lieux de stockage depuis Wino. Maîtrisez ainsi la gestion de stock pour éviter le sous-stockage ou sur-stockage dans votre épicerie.',
            },
            {
              decoration: 'alwadood',
              media: 'screenshot_speciality-delicatessen_fidelity.png',
              alt: 'Fidélisation client',
              label: 'Fidélisation client',
              title: 'Une caisse enregistreuse pour fidéliser vos clients',
              content: `Mettez en place un programme de fidélité intégré à la caisse enregistreuse pour récompenser les clients fidèles à votre épicerie. Paramétrez le programme pour proposer des bons d'achat, des réductions et des produits offerts. Prolongez l'expérience client en créant un site e-commerce pour attirer de nouveaux clients. Voilà une combinaison gagnante pour devenir incontournable et augmenter son chiffre d'affaires.`,
            },
          ],
        },
      ]}
    />
  )
}
